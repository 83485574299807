'use strict';

angular.module('windmanagerApp')
  .directive('addFinding', function() {
    return {
      templateUrl: 'components/directives/finding/addFinding/addFinding.html',
      restrict: 'EA',
      scope: {
        blade: '='
      },
      controller: 'AddEditFindingCtrl',
      controllerAs: 'addFinding',
      link: function(scope, element, attrs) {
      }
    };
  });
